<template>
  <div class="dashboard">
    <div class="container-fluid mt--6">
      <div class="row" v-if="!isMobile">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">{{ getname }}</h3>
            </div>

            <div class="card-body min-vh" v-loading="loading">
              <div class="card p-4 mb-4">
                <CompareCurrency ref="comparecurrency" theme="light" type="plus" :currencies="curencies"/>
              </div>
              <ul
                class="list-group mb-4"
                v-for="currency in Object.keys(bankAccount)"
                :key="currency"
              >
                <li
                  class="list-group-item"
                  v-for="item of bankAccount[currency]"
                  :key="item.uid"
                >
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">
                      {{ currency }} {{ item.cardholderid }}
                    </h6>
                    <div
                      class="mb-2"
                      v-if="['GBP', 'EUR'].includes(item.currency)"
                    >
                      <div class="mb-2 text-xs">
                        {{
                          item.currency === "GBP" ? "UK Domestic" : "EUR SEPA"
                        }}
                      </div>
                      <div class="mb-2 text-xs">
                        {{ labelAccount(item.currency) }}:
                        <b>{{ item.accountnumber }}</b>
                        {{ labelSortcode(item.currency) }}:
                        <b>{{ item.sortcode }}</b>
                      </div>
                    </div>
                    <div class="mb-2 text-xs">International</div>
                    <div class="mb-2 text-xs">
                      IBAN: <b>{{ item.international.IBAN }}</b> 
                      BIC: <b>{{ item.international.BIC }}</b> 
                      Reference: <b>{{ item.international.reference }}</b>
                    </div>
                    <div class="text-xs">
                      Correspondent Bank SWIFT:
                      <b>{{ item.international.correspondent }}</b>
                    </div>
                    <div
                      class="has-card"
                      v-if="item.hascard"
                      @click="$router.push('/card')"
                    >
                      <img :src="'/img/card/plus_personal.png'" alt="logo" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
            <div class="card mb-3">
              <div class="card-body">
                <Shortcut />
              </div>
            </div>
        </div>
      </div>

      <div class="row" v-if="isMobile">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">{{ getname }}</h3>
            </div>
            <div class="card-body  justify-content-md-center">
              <div class="menu-tile">
                <router-link :to="{ path: '/payment' }">
                  <svg-icon icon-class="payment" />
                  <p class="title">Make Payment</p>
                </router-link>
              </div>
              <div class="menu-tile">
                <router-link :to="{ path: '/statement' }">
                  <svg-icon icon-class="statement" />
                  <p class="title">Statement</p>
                </router-link>
              </div>
              <div class="menu-tile">
                <router-link :to="{ path: '/payee' }">
                  <svg-icon icon-class="payee" />
                  <p class="title">Manage Payees</p>
                </router-link>
              </div>
              <div class="menu-tile">
                <router-link :to="{ path: '/auto-fx' }">
                  <svg-icon icon-class="auto-fx" />
                  <p class="title">Auto FX</p>
                </router-link>
              </div>
              <div class="menu-tile">
                <router-link :to="{ path: '/card/issue' }">
                  <svg-icon icon-class="card" />
                  <p class="title">Request Debit Card</p>
                </router-link>
              </div>
              <div class="menu-tile">
                <router-link :to="{ path: '/profile' }">
                  <svg-icon icon-class="profile" />
                  <p class="title">View Profile</p>
                </router-link>
              </div>
              <div class="menu-tile" v-if="isBusiness">
                <router-link :to="{ path: '/request-fx' }">
                  <svg-icon icon-class="request-fx" />
                  <p class="title">Request FX</p>
                </router-link>
              </div>
              <div class="menu-tile">
                <router-link :to="{ path: '/introducer' }">
                  <svg-icon icon-class="introducer" v-if="isIntroducer" />
                  <p class="title">Invite Friends <br><span style="color:red; font-weight:bold;">Get £50</span></p>
                </router-link>
              </div>
              <div class="menu-tile" @click="updatePin()">
                  <svg-icon icon-class="mobile"/>
                  <p class="title">Change PIN</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PinDialog ref="pincodedialog" :show-close-button="false"/>
    <DeviceSettingDialog/>
    <agreement ref="agreement" v-on:submited="Agreemented"/>
    <renew-passport/>
  </div>
</template>
<script>
import BankApi from "@/api/bankaccount";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import PinApi from "@/api/pin";
import DeviceSettingDialog from '@/views/Device/SettingDialog.vue'
import Agreement from '@/components/Agreement.vue'
import Shortcut from '@/components/MenuShortcut.vue'
import CompareCurrency from '@/components/Chart/CompareCurrency';
import RenewPassport from '@/components/RenewPassport.vue'
export default {
  name: "dashboard",
  components:{
    PinDialog,
    DeviceSettingDialog,
    Agreement,
    Shortcut,
    CompareCurrency,
    RenewPassport
  },
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    getname() {
      return this.$store.getters.name;
    },
    multiaccount() {
      return this.$store.getters.multiaccount;
    },
    isBusiness() {
      return this.$store.getters.business;
    },
    isIntroducer() {
      return this.$store.getters.introducer;
    },
    isAgreement() {
      return this.$store.getters.agreement;
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      dialogAddDevice: false,
      bankAccount: [],
      curencies:[]
    };
  },
  methods: {
    labelAccount(currency) {
      return currency.toUpperCase() === "GBP" ? "Acount Number" : "IBAN";
    },
    labelSortcode(currency) {
      return currency.toUpperCase() === "GBP" ? "Sort Code" : "BIC";
    },
    getBankAccount() {
      this.loading = true;
      BankApi.getMultiAccount().then(({ result, data, message }) => {
        this.loading = false;
        if (result) {
          this.bankAccount = data;
          this.curencies = Object.keys(data);
        } else {
          this.$message.error(message);
        }
      });
    },
    
    balance() {
      this.$emit("updateBalance");
    },
    updatePin() {
      this.$refs.pincodedialog.show('update-pin');
    },
    Agreemented(){
       this.$store.dispatch('user/setAgreement', true).then(() => {
         this.getBankAccount();
       })
      
    },
  },
  mounted() {
    if(this.isMobile) {
      PinApi.getResetPin().then(({ result, data }) => {
        if (result && data.resetpin) {
          this.$refs.pincodedialog.show('reset-pin');
        }else{
          this.$refs.pincodedialog.show('create-pin');
        }
      });
    }
    // console.log(this.isAgreement)
    if(!this.$store.getters.agreement){
      this.$refs.agreement.open();
      return false
    }else{
      this.getBankAccount();
    }
  },
};
</script>
<style lang="scss">
.dashboard {
  .has-card {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 100px;

    img {
      width: 100%;
      box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }
}
</style>
