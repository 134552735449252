<template>
  <div>
    <el-dialog
      title="Renew passport"
      :visible.sync="dialogExpired"
      width="60%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div>
        <img
          style="width: 20%"
          class="img-center"
          src="/img/theme/passport.png"
          alt=""
        />
        <p class="text-md text-center">
          <b>{{ message }}</b>
        </p>
        <!-- <div v-if="status == 'expired'">
          <p class="text-sm text-center">
          Renew Passport on AB Money Application
          </p>
          
          <a href="http://app.ab-fx.com"> 
          <img  :src="'/img/theme/app-qr.png'" class="img-center" style="width:50%;" title="qrcode" alt="img" /></a>
        </div> -->
        <div class="text-center mt-6" v-if="status == 'expired'">
          <el-button type="default" @click="dialogExpired = false"
            >Update later</el-button
          >
          <el-button
            type="primary"
            @click="updateNow()"
            :loading="submitting"
            :disabled="submitting"
            >Update now</el-button
          >
        </div>
        <div class="text-center mt-6" v-else>
          <el-button type="primary" @click="dialogExpired = false"
            >OK, I got it</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="Renew passport"
      :visible.sync="dialogNotify"
      width="60%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div>
        <div>
          <p class="text-md text-center">
            <b
              >Please install AB FX App on your mobile phone and finish update
              you profile</b
            >
          </p>

          <a href="http://app.ab-fx.com">
            <img
              :src="'/img/theme/app-qr.png'"
              class="img-center"
              style="width: 30%"
              title="qrcode"
              alt="img"
          /></a>
        </div>
        <div>
          <p class="text-md text-center">
            <b
              >After you install app and login. Please follow below step</b
            >
          </p>
          <img style="width: 50%" class="img-center"
          src="/img/modal/giude-renew-passport.png" alt= />
        </div>

        <div class="text-center mt-6">
          <el-button type="primary" @click="dialogNotify = false"
            >OK, I got it</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="Renew passport"
      :visible.sync="dialogGuide"
      width="60%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div>
        <p class="text-md text-center">
            <b
              >Please use AB FX App on your mobile phone and finish update</b
            >
          </p>
        <img
          style="width: 50%"
          class="img-center"
          src="/img/modal/giude-renew-passport.png"
          alt=""
        />

        <div class="text-center mt-6">
          <el-button type="primary" @click="dialogGuide = false"
            >OK, I got it</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CustomerApi from "@/api/customer";
export default {
  data() {
    return {
      dialogExpired: false,
      dialogNotify: false,
      dialogGuide: false,
      submitting: false,
      message: "",
      status: "",
    };
  },
  methods: {
    getAccount() {
      CustomerApi.account().then(({ result, data }) => {
        if (result) {
          data["token"] = this.$store.getters.token;
          //console.log(data)
          this.$store.dispatch("user/login", data).then(() => {
            this.status = data["idStatus"]["status"];
            if (this.status != "active") {
              this.dialogExpired = true;
              this.message = data["idStatus"]["message"];
            }
          });
        }
      });
    },
    updateNow() {
      this.submitting = true;
      setTimeout(() => {
        this.notified();
      }, 2000);
    },
    notified() {
      this.submitting = false;
      this.dialogExpired = false;

      CustomerApi.renewPassportNotification().then(({ result }) => { 
        if (result) {
          this.dialogGuide = true;
        } else { 
          this.dialogNotify = true;
        }
      })

    },
  },
  mounted() {
    this.getAccount();
  },
};
</script>

<style></style>
