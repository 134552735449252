<template>
  <el-dialog
    ref="termsdialog"
    title="Terms and Conditions"
    :show-close="false"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :fullscreen="false"
    width="80%">
    <div>
      <terms-account :show-header="false" v-if="nextTerms === 1"/>
      <terms-card :show-header="false" v-if="nextTerms === 2"/>
      <terms-ab :show-header="false" v-if="nextTerms === 3"/>
    </div>
    <span slot="footer" class="dialog-footer" v-if="nextTerms != 3">
      <el-button v-if="nextTerms != 1" @click="clickBack">Back</el-button> 
      <el-button type="primary" @click="clickNext" >Next</el-button>
    </span>
    <span slot="footer" class="dialog-footer" v-if="nextTerms == 3">
      <div v-if="errorMsg" class="text-danger">{{ errorMsg }}</div>
      
      <el-checkbox style="margin-right:20px" v-model="agreement">I agree with terms and conditions</el-checkbox> 
      <el-button @click="clickBack">Back</el-button> 
      <el-button :disabled="!agreement" :loading="loading" type="primary" @click="onSubmit()">Confirm</el-button>
      <div></div>
    </span>
  </el-dialog>
</template>

<script>
import TermsAccount from '@/views/Terms/TermsConditionAccount.vue'
import TermsCard from '@/views/Terms/TermsConditionCard.vue'
import TermsAb from '@/views/Terms/TermsCondition.vue'
import platform from 'platform'
import { Agreement } from '@/api/user' 
export default {
  components:{
    TermsAccount,
    TermsCard,
    TermsAb
  },
  computed: {
      isBusiness(){
        return this.$store.getters.business
      }
  },
  data(){
    return {
      loading: false,
      dialogVisible: false,
      errorMsg: "",
      agreement: false,
      nextTerms: 1,
      formData:{
        devicetype: 1,
        agreement_info: platform.description
      }
    }
  },
  methods:{
    open(){
      this.dialogVisible = true
    },
    close(){
      this.dialogVisible = false
    },
    clickBack(){
      if(this.nextTerms > 1){
        this.nextTerms--;
      }
    },
    clickNext(){
     
      this.$el.getElementsByClassName('el-dialog')[0].scrollIntoView()
      if(this.nextTerms < 3){
        this.nextTerms++;
      }
    },
    onSubmit(){
      this.loading = true
      Agreement(this.formData).then(({result, message})=>{
        this.loading = false
        if(result){ 
          this.close();
          this.$emit('submited')
        }else{
          this.errorMsg = message
        }
      })

    }
  }
}
</script>

<style>

</style>