<template>
  <div :class="`plus-terms container-fluid ${showHeader ? ' mt--6': ''}`">
    <div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">
          <div class="card-header position-relative" v-if="showHeader" >
            <h3 class="mb-0 text-center">CARD TERMS AND CONDITIONS</h3>
          </div>

          <div class="card-body">
            <div class="markdown-render" v-html="markdownToHtml()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import marked from 'marked';
export default {
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      markdown: "",
    }
  },
  computed: {
    isBusiness() {
      return this.$store.getters.business
    },
  },
  methods: {


    getMarkdownFile() {
      var url = "/terms-conditions/ab-plus/consumer-card-term-condition.md";
      if (this.isBusiness) {
        url = "/terms-conditions/ab-plus/corporate-card-term-condition.md";
      }
      axios.get(url).then((response) => {
        this.markdown = response.data;
      });
    },
    markdownToHtml() {
      return marked.parse(this.markdown);
    },
  },
  mounted() {
    this.getMarkdownFile()
  }

}
</script>

<style lang="scss">
.plus-terms {
  .markdown-render {
    min-height: 400px;

    * {
      font-size: 12px;
      font-weight: 500;
    }
  }
}</style>